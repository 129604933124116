.section{
    padding: 70px 0;
    position: relative;
    background-color: #FFFFFF;
}
.section-with-space{
    padding: 60px 0;
}
.section-gray{
    background-color: #EEEEEE;
}
.section-nude{
    background-color: $bg-nude;
}
.section-gold{
    background-color: #caac90;
    color: $white-color;
}
.section-brown{
    background-color: #A59E94;
    color: $white-color;
}
.section-light-blue{
    background-color: $primary-color;
    color: $white-color;
}
.section-dark-blue{
    background-color: #506367;
    color: $white-color;
}
.section-dark{
    padding: 32px 0;
    background-color: #1C1939;
}
.section-image,
.section-gold,
.section-dark{
    position: relative;

    .title,
    .info-title,
    .card-plain .card-title{
        color: $white-color !important;
    }
    .category,
    .description,
    .card-plain .card-description,
    .card-plain .card-category{
        color: rgba($white-color, .7);
    }
    hr{
        border-color: rgba(255, 255, 255, 0.19);
    }
}
.page-header,
.carousel-caption{
    .title,
    .info-title{
        color: $white-color;
    }
}
[class*="features-"],
[class*="team-"],
[class*="projects-"],
[class*="pricing-"],
[class*="testimonials-"],
[class*="contactus-"]{
    padding: 80px 0;
}
.section-image{
    background-position: center center;
    background-size: cover;

    &:before{
        background-color: rgba(0, 0, 0, 0.5);
        content: "";
        display: block;
        height: 100%;
        left: 0;
        top: 0;
        position: absolute;
        width: 100%;
        z-index: 1;
    }
    & .container{
        position: relative;
        z-index: 2;
    }
}

.section-login{
    min-height: 700px;
}

.section-metrics {
    padding: 70px 0 0;

    .card {
        color: $white-color;
        background-color: $primary-color;
        width: 100%;
    }
    .card-body {
        position: relative;
        z-index: 1;
    }
    .metrics-icon {
        filter: brightness(0) invert(1);
        position: absolute;
        height: 48px;
        top: 32px;
        right: 14px;
        opacity: 0.3;
        border-radius: 0;
        z-index: 0;
    }
    p {
        font-size: 14px;
    }
}

.download-app {
    background: no-repeat, $primary-color;
    background-size: contain;
    border-radius: 12px;

    .download-button-img {
        height: 62px;
        object-fit: contain;
    }
}

.section-contact {
    padding: 100px 0;
    position: relative;

    .flags-block {
        position: relative;
        z-index: 0;
        img {
            height: 48px;
            width: 48px;
            object-fit: cover;
            transform: scale(0.8);
            position: absolute;
            border-radius: 24px;
        }
        .guinea-flag {
            left: 24%;
            top: -32px;
            animation: pulse 4s ease-in-out infinite;
        }
        .kenya-flag {
            right: 24%;
            top: -32px;
            animation: pulse 4s ease-in-out infinite;
        }
        .ghana-flag {
            left: 10%;
            top: 120px;
            animation: pulse 3s ease-in infinite;;
        }
        .rwanda-flag {
            right: 10%;
            top: 120px;
            animation: pulse 3s ease-in infinite;
        }
    }
    .contact-block {
        z-index: 1;
        position: relative;
    }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    /* IE10+ CSS styles go here */
    .page-header{
        .content-center{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -75%);
        }
        .presentation-title{
            background: none !important;
        }
    }
}


[class*="features-"],
[class*="team-"],
[class*="projects-"],
[class*="pricing-"],
[class*="testimonials-"],
[class*="contactus-"] {
    padding: 80px 0;
}
.section-image {
    background-position: center center;
    background-size: cover;

    &:before {
        background-color: rgba(0, 0, 0, 0.5);
        content: "";
        display: block;
        height: 100%;
        left: 0;
        top: 0;
        position: absolute;
        width: 100%;
        z-index: 1;
    }
    & .container {
        position: relative;
        z-index: 2;
    }
}

.sharing-area .btn {
    font-size: 18px;
    padding: 8px 15px;
}
.texted-logo {
    width: 202px;
    padding-left: 8px;
}
.service-card {
    min-height: 320px;

    .card {
        &:hover {
            box-shadow: 0 6px 10px -4px rgba(0, 0, 0, 0.15) !important;
            transform: none !important;
        }
    }


    .flip-card {
        background-color: transparent;
        height: 320px;
        perspective: 1000px;
        margin-bottom: 20px;
    }

    .flip-card-inner {
        position: relative;
        width: 100%;
        height: 100%;
        text-align: center;
        transition: transform 0.6s;
        transform-style: preserve-3d;
    }

    .flip-card:hover .flip-card-inner {
        transform: rotateY(180deg);
    }

    .flip-card-front, .flip-card-back {
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 20px;
        width: 100%;
        height: 100%;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        border-radius: 12px;
        box-shadow: 0px 12px 19px -7px rgba(0, 0, 0, 0.3);
    }

    .flip-card-front {
        background-color: $smoke-bg;
        .icon {
            font-size: 4.2em;
        }
    }

    .flip-card-back {
        background-color: $primary-color;
        transform: rotateY(180deg);

        .description {
            color: $white-color;
            font-size: 18px;
        }
    }
}
.teams-cards {
    .card {
        flex-direction: row;
        margin-bottom: 42px;
        .teams-image {
            width: 242px;
            height: 242px;
            border-radius: 12px 0 0 12px;
        }
        .card-body {
            flex: 1;
        }
        .member-name {
            margin-top: 0;
            margin-bottom: 8px;
            font-weight: 600;
            color: $font-color;
        }
        .member-role {
            margin-top: 0;
            font-weight: 600;
            color: $dark-gray;
            font-size: 14px;
        }
        .member-bio {
            color: $dark-gray;
        }

    }
}

.advisors-cards {
    .card {
        flex-direction: row;
        margin-bottom: 42px;
        .teams-image {
            width: 242px;
            height: 262px;
            object-fit: cover;
            border-radius: 12px 0 0 12px;
        }
        .card-body {
            flex: 1;
        }
        .member-name {
            margin-top: 0;
            margin-bottom: 8px;
            font-weight: 600;
            color: $font-color;
        }
        .member-role {
            margin-top: 0;
            font-weight: 600;
            color: $dark-gray;
            font-size: 14px;
        }
        .member-bio {
            color: $dark-gray;
        }

    }
}
