@keyframes float {
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(-20px);
  }
  100% {
    transform: translatey(0px);
  }
}
.floating-hero-image {
  transform: translatey(0px);
  animation: float 6s ease-in-out infinite;
}

#barner-image {
  border-radius: 30% 10% 10% 40%;
  margin-left: 15%;
}

@keyframes pulse {
  0% {
    transform: scale(0.8);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.8);
  }
}

